@import "../../../../assets/style/index.scss";
@include admin_container;

.center-container {
    .user-detail {
        width: 100%;
        display: flex;
        align-items: center;

        form {
            width: 100%;
            display: flex;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            .user-profile {
                width: 100%;
                padding-right: 0px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding-right: 0px;
                }

                .user_form_wpr {
                    padding: 0;
                    margin: 0;

                    .form_group {
                        width: 100%;

                        .input-div {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 2px 10px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            @media screen and (max-width: 768px) {
                                margin-bottom: 0;
                            }
                        }

                        label {
                            @include defaultFontStyle(600, 16, 22, $black-color);
                            display: block;
                        }

                        input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 15px 21px 16px;
                            background: $white-color;
                            outline: none;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 22, #baa08d);
                            }
                        }

                        .amount_section {
                            // display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .form-service-location {
                                width: 74% !important;
                            }

                            .label-div {
                                display: flex;
                                justify-content: space-between;
                            }

                            .sub_div-amount {
                                display: flex;
                            }

                            .rank-amount-section {
                                display: flex;
                            }

                            .rank-section {
                                max-width: 200px;
                                margin-right: 15px;
                            }

                            .service-location-btn {
                                position: absolute;
                                top: 57px;
                                right: 0;
                            }
                        }
                    }

                    .form_group-gender {

                        width: 100%;
                        position: relative;

                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                            width: 100%;
                        }

                        label {
                            @include defaultFontStyle(600, 16, 21.79, $black-color);
                            margin-bottom: 9px;
                            display: block;
                            margin-top: 16px;
                        }

                        .input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 16px 21px 19px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 21.79, #baa08d);
                            }
                        }
                    }

                    .form_group {
                        position: relative;

                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                            width: 100%;
                        }

                        label {
                            @include defaultFontStyle(600, 16, 22, $black-color);
                            display: block;
                            margin-bottom: 9px;
                            margin-top: 16px;
                        }

                        .input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 16px 21px 19px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 21.79, #baa08d);
                            }
                        }
                    }

                    .status {
                        margin-top: 16px;
                        padding-bottom: 8px;

                        label {
                            @include defaultFontStyle(600, 16, 21.79, $black-color);
                        }
                    }

                    div {
                        margin-top: 0px;
                        padding-bottom: 0px;

                        label {
                            @include defaultFontStyle(600, 16, 21.79, $black-color);
                        }
                    }
                }
            }
        }
    }
}

.searchbar {
    margin-left: 15px;
    position: relative;

    img {
        height: 46px;
        width: 46px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        border-radius: 0 8px 8px 0;
    }
}

.btn_group {
    padding: 5px 0px;
    padding-bottom: 58px;
    width: 200px;
    margin: auto;

    @media screen and (max-width: 768px) {
        padding: 20px 0px 5px;
    }

    div {
        width: 100%;
        display: flex;


        .btn {
            max-width: 100%;
            width: 100%;
            text-align: right;

            @media screen and (max-width: 768px) {
                text-align: center;
                display: flex;
            }

            .pt_btn {
                margin: 5px 0;
                max-width: 110px;
                width: 100%;
                height: 44px;
                padding: 12px 10px;
                margin-right: 8.5px;
                @include defaultFontStyle(600, 15, 20.43, $white-color);

                // @media screen and (max-width: 768px) {
                //   max-width: 50%;
                //   width: 100%;
                //   margin: 0px 0;
                //   padding: 12px 33px;
                // }
            }
        }
    }
}

.user-management {
    .add-tab {
        @include flex_align_center;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-bottom: 11px;

        @media screen and (max-width: 511px) {
            display: block;
        }

        .tab-btn {
            margin-bottom: 10px;

            ul {
                li {
                    display: inline-block;
                    padding: 12px 0px;

                    a {
                        border-radius: 8px 0px 0px 8px;
                        @include defaultFontStyle(700, 16, 22, #231f20);
                        text-align: center;
                        padding: 12px 22px;
                        background: $bg-light;

                        &.active-tab {
                            background: $dark-orang;
                            color: $white-color;
                        }
                    }
                }
            }
        }

        .add-new-btn {
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 511px) {
                margin-top: 20px;
            }

            .filter_text {
                text-align: left;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                color: #262521;
                padding: 14px 10px 16px;
            }

            .sort-dd {
                border-radius: 8px;
                padding: 12px 15px;
                margin-bottom: 10px;
                font-family: "Open Sans", sans-serif !important;
                font-weight: bold;
                border-color: #a8a0a0;

                @media (max-width: 600px) {
                    & {
                        width: 100%;
                        display: block;
                    }
                }
            }

            .add-btn {
                min-height: 44px;
                background: #28d;
                border-radius: 8px;
                @include defaultFontStyle(700, 16, 22, $white-color);
                padding: 11px 19px;
                display: flex;
                margin-right: 15px;
                margin-bottom: 10px;
                cursor: pointer;

                img {
                    width: 26px;
                    padding-right: 11px;
                }

                @media screen and (max-width: 600px) {
                    margin-right: 0;
                    width: 100%;

                }

                &:last-child {
                    margin-right: 0px;

                    @media screen and (max-width: 511px) {
                        margin-left: 0px;
                    }
                }
            }
        }
    }

    .table-listing {
        .sorting-data {
            .data-find {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;

                .form_group {
                    position: relative;
                    margin-bottom: 15px;
                    max-width: 296px;
                    margin-right: 20px;
                    padding-bottom: 0;

                    @media screen and (max-width: 687px) {
                        max-width: 100%;
                        margin-right: 0;
                        margin-bottom: 0;
                    }

                    label {
                        @include defaultFontStyle(600, 16, 22, $black-color);
                        display: block;
                        margin-bottom: 9px;
                        margin-top: 10px;
                    }

                    .input {
                        @include defaultFontStyle(400, 15, 20, $black-color);
                        width: 100%;
                        padding: 10px 13px;
                        background: $white-color;
                        border: 1px solid #d6c7bc;
                        outline: none;
                        border-radius: 8px;
                        max-width: 296px;
                        appearance: none;
                        -webkit-appearance: none;

                        @media screen and (max-width: 687px) {
                            max-width: 100%;
                        }

                        &::placeholder {
                            @include defaultFontStyle(400, 15, 20, #baa08d);
                        }
                    }

                    &.arrow_add {
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            border-top: 5px solid #baa08d;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            top: 60px;
                            right: 20px;
                            z-index: 1;
                        }
                    }
                }

                .filter-btn {
                    margin-bottom: 15px;

                    @media screen and (max-width: 687px) {
                        margin-top: 20px;
                    }

                    button {
                        background: $dark-orang;
                        border-radius: 8px;
                        cursor: pointer;
                        border: none;
                        text-align: center;
                        @include defaultFontStyle(600, 15, 20, $white-color);
                        padding: 12px 49px;

                        @media screen and (max-width: 391px) {
                            width: 100%;
                            margin-right: 20px;
                        }

                        &:last-child {
                            background: $white-color;
                            border: 1.4px solid $dark-orang;
                            box-sizing: border-box;
                            color: $dark-orang;
                            margin-left: 15px;
                            padding: 12px 32px;

                            @media screen and (max-width: 391px) {
                                width: 100%;
                                margin-left: 0;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }

        .table-responsive {
            margin-top: 10px;
            margin-bottom: 20px;
            overflow: auto;

            .table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    th {
                        text-align: left;
                        @include defaultFontStyle(700, 15, 20, #262521);
                        padding: 14px 10px 16px;
                        border-top: 1px solid #d6c7bc;
                        border-bottom: 1px solid #d6c7bc;
                        min-width: 210px;

                        &:last-child {
                            min-width: 170px;
                        }
                    }
                }

                tbody {
                    td {
                        text-align: left;
                        @include defaultFontStyle(400, 14, 19, #262521);
                        padding: 22px 10px 25px;
                        border-bottom: 1px solid #d6c7bc;

                        &.action_icon {
                            span {
                                margin-right: 7px;

                                img {
                                    width: 34px;
                                    height: 34px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pagination {
            margin-left: 0;
            margin-bottom: 1rem;
        }

        .pagination li {
            display: inline-block;
        }

        .pagination .previous .next .disabled {
            padding: 0.1875rem 0.625rem;
            color: #cacaca;
            cursor: default;
        }

        .pagination .selected {
            // padding: 0.1875rem 0.625rem;
            background: #2199e8;
            color: #fefefe;
            cursor: default;
        }

        .pagination a {
            color: #0a0a0a;
            display: block;
            padding: 0.1875rem 0.625rem;
            border-radius: 0;
        }

        .pagination li :hover {
            padding: 0.1875rem 0.625rem;
            background: #ddddde;
        }

    }
}

.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 265px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 700px;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


/* popup css */

.admin-pop-up {

    display: inline-block;
    // max-width: 412px;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    position: fixed;

    // padding-top: 5%;
    @media screen and (max-width: 480px) {
        max-width: 92%;
    }

    .inner-div {
        width: 412px;
        box-shadow: 0px -6px 0px -1px #dc3a3a;
        border-radius: 7px 7px 8px 8px;
        margin-top: 5px;
        background-color: $white-color;

        div {
            text-align: center;
            padding: 15px 0;
        }

        h1 {
            text-align: center;
            @include defaultFontStyle(700, 20, 27.24px, $black-color);
            padding-bottom: 14px;
        }

        p {
            text-align: center;
            @include defaultFontStyle(400, 18, 24.51px, $grey-color);
            padding-bottom: 26px;
        }



        .btn_group {
            display: flex;
            align-content: center;
            width: 100%;
            justify-content: center;
            padding-bottom: 32px;

            .pt_btn {
                max-width: 110px;
                width: 100%;
                height: 44px;
                padding: 12px 10px;
                @include defaultFontStyle(600, 15, 20.43, $white-color);
            }

            .pt_btn1 {
                max-width: 110px;
                width: 100%;
                height: 44px;
                padding: 12px 10px;
                @include defaultFontStyle(600, 15, 20.43, #000);
                background: #ebebeb;
                border: 1.4px solid #ebebeb;
                box-sizing: border-box;
                border-radius: 8px;
                margin-right: 17px;
                cursor: pointer;
            }
        }
    }

    .close-img {
        position: absolute;
        right: 20px;
        width: 34px;
        height: 34px;
        cursor: pointer;
    }
}



.label_section {
    display: flex;
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 6px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}