.topnav {
    overflow: hidden;
    background-color: #222;
}

.topnav h4 {
    float: left;
    display: block;
    color: #fff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav .login-container {
    float: right;
}

.topnav .login-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background-color: #555;
    color: white;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.topnav .login-container button:hover {
    background-color: #28d;
}