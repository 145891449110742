$primary-font: 'Open Sans';

/* color variables */
$lifgt-yellow: #FFF33B;
$dark-yellow: #FDC70C;
$dark-orang: #F3903F;
$light-blue: #28d;
$black-color: #231F20;
$grey-color: #7B6E63;
$light-color :#9F9287;
$bg-light: #FAF2EC;
$white-color :#FFFFFF;
$black: #000000;
$red: #E73E3E;

$color_green: #16C98D;
$color_blue: #2C3543;
$red: #e74c3c;

$width: 100%;