article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

body,
ol,
ul,
li,
div,
p,
span,
form,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
th,
tbody,
tr,
td,
img,
input,
textarea,
dd,
dt,
dl {
    word-wrap: break-word;
    outline: none;
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
    overflow: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
    -webkit-margin-before: 0px;
    -webkit-margin-after: 0px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    margin: 0;
    padding: 0;
}

a img,
img {
    border: none;
    outline: none;
    width: auto;
    max-width: 100%;
}

a {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
}

li {
    list-style-type: none;
}

input {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    background-color: #fff;
}

ul,
menu,
dir {
    margin: 0px;
    padding: 0px;
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:after,
    &:before {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

:focus {
    outline: none;
}

li:last-child,
p:last-child {
    margin-bottom: 0 !important;
}

/* common css  */
.container {
    max-width: 768px;
    margin: auto;
    width: 100%;
    position: relative;
}

.pt-container {
    overflow: hidden;
    max-width: 768px;
    margin: auto;
    width: 100%;
}
.no-data{
    text-align: center;
}