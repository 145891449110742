@import "./variables";

/* For Fonts */
@mixin defaultFontStyle($fontWeight: null,
  $fontSize: null,
  $lineHeight: null,
  $color: null) {
  font-family: $primary-font;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
  color: $color;
}

// Media Queries
@mixin mediaQuery($point) {
  @media screen and (max-width: $point) {
    @content;
  }
}

@mixin minmediaQuery($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}

// Content
@mixin content {
  content: "";
  position: absolute;
}

// Flex
@mixin flex {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

@mixin d_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin align_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


@mixin flex_align_center {
  @include d_flex;
  @include align_center;
}

// Button Reset
@mixin buttonReset {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}


/* admin center part */
.dashboard_full {
  .dashboard-wrapper {
    width: 100% !important;
  }
}

@mixin admin_container {
  .dashboard-wrapper {
    background-color: #ebebeb;
    display: flex;
    width: 100%;

    // margin-left: auto;
    // padding-top: 80px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }

    .inner-div {
      width: 100%;
      padding: 0 21px;

      .heading {
        h3 {
          @include defaultFontStyle(700, 20, 27.24, $black-color);
          padding: 23px 0;
          text-align: center;
        }
      }
    }

    .center-container {
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      width: 900px;
      margin: auto;
      margin-bottom: 24px;
      padding: 55px 40px;
      // min-height: 928px;
    }

    & .lobbylist {
      width: 100%;
    }
  }

  .action_icon {
    a {
      margin-right: 7px;

      img {
        width: 34px;
        height: 34px;
      }
    }
  }
}