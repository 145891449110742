@import "../../../../assets/style/index.scss";
@include admin_container;

.center-container {
    .user-detail {
        width: 100%;
        display: flex;
        align-items: center;

        form {
            width: 100%;
            display: flex;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            .user-profile {
                width: 100%;
                padding-right: 0px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding-right: 0px;
                }

                .user_form_wpr {
                    padding: 0;
                    margin: 0;

                    .form_group {
                        width: 100%;

                        .input-div {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 2px 10px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            @media screen and (max-width: 768px) {
                                margin-bottom: 0;
                            }
                        }

                        label {
                            @include defaultFontStyle(600, 16, 22, $black-color);
                            display: block;
                        }

                        input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 15px 21px 16px;
                            background: $white-color;
                            outline: none;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 22, #baa08d);
                            }
                        }

                        .amount_section {
                            // display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .form-service-location {
                                width: 74%;
                            }

                            .label-div {
                                display: flex;
                                justify-content: space-between;
                            }

                            .sub_div-amount {
                                display: flex;
                            }

                            .rank-amount-section {
                                display: flex;
                            }

                            .rank-section {
                                max-width: 200px;
                                margin-right: 15px;
                            }

                            .service-location-btn {
                                position: absolute;
                                top: 57px;
                                right: 0;
                            }
                        }
                    }

                    .form_group-gender {

                        width: 100%;
                        position: relative;

                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                            width: 100%;
                        }

                        label {
                            @include defaultFontStyle(600, 16, 21.79, $black-color);
                            margin-bottom: 9px;
                            display: block;
                            margin-top: 16px;
                        }

                        .input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 16px 21px 19px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 21.79, #baa08d);
                            }
                        }
                    }

                    .form_group {
                        position: relative;

                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                            width: 100%;
                        }

                        label {
                            @include defaultFontStyle(600, 16, 22, $black-color);
                            display: block;
                            margin-bottom: 9px;
                            margin-top: 16px;
                        }

                        .input {
                            @include defaultFontStyle(400, 16, 22, $black-color);
                            width: 100%;
                            padding: 16px 21px 19px;
                            background: $white-color;
                            border: 1px solid #d6c7bc;
                            outline: none;
                            border-radius: 8px;

                            &::placeholder {
                                @include defaultFontStyle(400, 16, 21.79, #baa08d);
                            }
                        }

                    }

                    div {
                        margin-top: 0px;
                        padding-bottom: 3px;

                        label {
                            @include defaultFontStyle(600, 16, 21.79, $black-color);
                        }
                    }
                }
            }
        }
    }
}

.btn_group {
    padding: 5px 0px;
    padding-bottom: 58px;
    width: 200px;
    margin: auto;

    @media screen and (max-width: 768px) {
        padding: 20px 0px 5px;
    }

    div {
        width: 100%;
        display: flex;

        .btn {
            max-width: 100%;
            width: 100%;
            text-align: right;

            @media screen and (max-width: 768px) {
                text-align: center;
                display: flex;
            }

            .pt_btn {
                margin: 5px 0;
                max-width: 110px;
                width: 100%;
                height: 44px;
                padding: 12px 10px;
                margin-right: 8.5px;
                @include defaultFontStyle(600, 15, 20.43, $white-color);

                // @media screen and (max-width: 768px) {
                //   max-width: 50%;
                //   width: 100%;
                //   margin: 0px 0;
                //   padding: 12px 33px;
                // }
            }
        }
    }
}

.invalid-feedback {
    color: #b70707;
}

.label_section {
    display: flex;
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 6px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.selected-icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}