@import "/src/component/styles/scss/main.scss";

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import "../src/assets/style/index.scss";

body {
    background: #ffff;
    font-family: 'Open Sans', sans-serif;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: $black;
    text-align: center;
    border-radius: 5px;
    padding: 9px 24px;
    position: absolute;
    z-index: 1;
    bottom: 45px;
    right: -23px;
    margin-left: -60px;
    @include defaultFontStyle(600, 11, 13, $white-color);

    &.credit-tooltip {
        right: -23px;
    }

    &.del-rat-tooltip {
        right: -25px;
    }

    &.rest-pass {
        right: -41px;
        padding: 9px 13px;
    }
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 49%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

// Spinner

#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

#cover-spin::after {
    content: "";
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: $dark-orang;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* switch toggel button */
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;

    input {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d6c7bc;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &::before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 6px;
            bottom: 6px;
            background-color: $white-color;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &.round {
            border-radius: 50px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    input:checked+.slider {
        background-color: #28a745;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
    }
}