// User Form
.user_form {
  // padding: 0 15px;
  background: $white-color;
}
// Banner
// .user_form_banner {
//   height: 222px;
//   margin-bottom: 28px;
//   // min-height: 143px;
//   // max-height: 143px;
//   overflow: hidden;
//   border-radius: 0 0 15px 15px;
//   position: relative;

//   img {
//     font-size: 0;
//     @include square(100%);
//     object-fit: cover;
//   }

//   .back {
//     width: 38px;
//     height: 38px;
//     position: absolute;
//     top: 19px;
//     left: 15px;
//     display: inline-block;
//     background: rgba(0, 0, 0, 0.4);
//     backdrop-filter: blur(6px);
//     border-radius: 10px;
//     .arrow {
//       width: 100%;
//       padding: 11px;
//     }
//   }
// }

// Title
.user_form_header {
  // padding-bottom: 22px;
  padding: 0 15px 22px;
  h2 {
    @include defaultFontStyle(800, 23, 31, $black-color);
    position: relative;
    &:after {
      @include content;
      width: 30px;
      height: 4px;
      background: $dark-orang;
      bottom: -8px;
      left: 0;
      border-radius: 2px;
    }
  }
}
.user_form_wpr {
  padding: 0 15px;
  padding-bottom: 100px;

  @media screen and (max-width: 700px) {
    padding-bottom: 6px;
  }
}
// Form input
// .form_group_half {
//   @include flex_justify_between;
//   @media screen and (max-width: 345px) {
//     @include flex_dir_column;
//   }
//   .form_group {
//     max-width: calc(50% - 8px);
//     @include mediaQuery(345px) {
//       max-width: 100%;
//     }
//   }
// }
.form_group {
  padding-bottom: 16px;
  width: 100%;
  label {
    @include defaultFontStyle(600, 16, 22, $black-color);
    display: block;
    margin-bottom: 9px;
  }
  input {
    @include defaultFontStyle(400, 16, 22, $black-color);
    width: 100%;
    padding: 22px 10px 19px 21px;
    background: #faf2ec;
    border: none;
    outline: none;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::placeholder {
      @include defaultFontStyle(400, 16, 22, #baa08d);
    }
  }
}
// Buttons
.btn_group {
  padding-top: 14px;
}

.pt_btn {
  @include defaultFontStyle(700, 18, 25, $white-color);
  @include buttonReset;
  width: 100%;
  border-radius: 8px;
  padding: 18px 24px;
  transition: 0.2s linear;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &_orange {
    background: $light-blue;
  }
}

.user_form_info {
  margin-top: -7px;
  padding-bottom: 22px;
  padding-top: 20px;
  p {
    @include defaultFontStyle(600, 17, 23, $grey-color);
  }
}



.user_form_text {
  text-align: center;
  padding: 19px 0;
  p {
    @include defaultFontStyle(400, 17, 23, $black-color);
    @media screen and (max-width: 700px) {
      @include defaultFontStyle(400, 16, 23, $black-color);
    }
    a {
      text-decoration: underline;
      @include defaultFontStyle(700, 17, 23, $black-color);
    }
  }
}

.user_form_link {
  text-align: right;
  padding: 9px 0;
  a {
    @include defaultFontStyle(600, 17, 23, $black-color);
  }
  @media screen and (max-width: 700px) {
    padding: 5px 0;
  }
}

// // Error Message
// .invalid-feedback {
//   span {
//     display: block;
//     @include defaultFontStyle(600, 12, 18, $red);
//     padding-top: 5px;
//   }
// }
