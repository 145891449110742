.login {
    width: 400px;
    margin: 16px auto;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 90vh;
    padding: 20px;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    .login-container {
        background: #ebebeb;
        padding: 48px;
        width: 460px;
        border-radius: 10px;

        label {
            padding-left: 17px;
            font: inherit;
            pointer-events: none;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 600;
        }

        p {
            padding: 12px;

            input {
                box-sizing: border-box;
                display: block;
                width: 100%;
                border-width: 1px;
                border-style: solid;
                padding: 16px;
                outline: 0;
                font-family: inherit;
                font-size: 0.95em;
                outline: none;
                border: none;
                border-radius: 6px;
            }

            input[type="email"],
            input[type="password"] {
                background: #fff;
                border-color: #bbb;
                color: #555;
                border-radius: 6px;

                :focus {
                    border-color: #888;
                }
            }

            input[type="submit"] {
                background: #28d;
                border-color: transparent;
                color: #fff;
                cursor: pointer;
                border-radius: 6px;

                &:hover {
                    background: #17c;
                }

                &:focus {
                    border-color: #05a;
                }
            }

            span.invalid-feedback {
                color: #d40606;
            }
        }
    }
}